import { formatDate } from "@/lib/dateFormats"
import { Discussion } from "@/models/Discussions"
import { buildDiscussionLink, commentOnLinesFormat } from "../../utils"
import { ResolveController } from "@/components/designSystem/Discussions/components/Comment/ResolveController"
import { Button } from "@/components"
import { Link2 } from "lucide-react"
import { CopyToClipboard } from "react-copy-to-clipboard"

interface DiscussionHeaderProps {
  discussion: Discussion
  handleResolveToggle: (resolvedState: boolean) => void
  isResolved: boolean
  id: string
  onCopyClick: () => void
}

export const DiscussionHeader = ({
  discussion,
  handleResolveToggle,
  isResolved,
  id,
  onCopyClick
}: DiscussionHeaderProps) => {
  return (
    <div className="mb-2 flex size-full items-center justify-between">
      <p className={`text-xxs ${discussion.outdated ? "text-error-dark" : "text-secondary"}`}>
        {commentOnLinesFormat(discussion)}
        {formatDate(discussion.created_at)}
        <span className="font-bold">{discussion.outdated ? " outdated" : ""}</span>
      </p>
      <div className="flex">
        <ResolveController discussion={discussion} handleResolveToggle={handleResolveToggle} isResolved={isResolved} />
        <CopyToClipboard text={buildDiscussionLink(id)} onCopy={onCopyClick}>
          <Button variant="outline" className="h-fit p-2">
            <Link2 size={16} />
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  )
}
