import { useDiscussions } from "@/services/store/useDiscussions"
import { Discussion } from "@/models/Discussions"

export const useDiscussionHover = (discussion: Discussion) => {
  const { setCurrentHoveredDiscussion, clearCurrentHoveredDiscussion } = useDiscussions()

  return {
    onMouseEnter: () => setCurrentHoveredDiscussion(discussion),
    onMouseLeave: () => clearCurrentHoveredDiscussion()
  }
}
