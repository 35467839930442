import { ArrowLeft, ArrowRight, Bell, ListFilter, MessageCircle } from "lucide-react"
import OpenIcon from "@/assets/icons/Open.svg?react"
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components"
import { useTranslation } from "react-i18next"
import { useDiscussions } from "@/services/store/useDiscussions"
import { cn } from "@/lib/utils.ts"
import { useState } from "react"
import { HEADER_HEIGHT } from "@/services/store/useChangeRequest"

export const DiscussionsHeader = () => {
  const { t } = useTranslation("Discussions")
  const {
    toggleExpandDiscussionsContainer,
    toggleManualRecalculationTrigger,
    discussionsExpanded,
    filteredDiscussions,
    filterOpenDiscussions,
    filterWaitingDiscussions,
    showAllDiscussions
  } = useDiscussions()

  const [filterState, setFilterState] = useState({
    isSelected: false,
    isFiltered: false,
    activeFilter: ""
  })

  const onToggle = () => {
    toggleExpandDiscussionsContainer()
    toggleManualRecalculationTrigger()
  }

  const applyFilter = (filterFn: () => void, activeFilter: string, isFiltered: boolean) => {
    filterFn() // Run the filter function
    setFilterState((prev) => ({ ...prev, isFiltered, activeFilter }))
  }

  return (
    <div
      id="discussions-header"
      className="sticky z-20 max-w-full border-b border-border bg-background p-3"
      style={{ top: HEADER_HEIGHT }}
    >
      <div className="flex items-center space-x-3">
        <Button
          onClick={onToggle}
          variant="outline"
          size="sm"
          className={`p-1 ${discussionsExpanded ? "size-7" : "-ml-7 size-7 rounded-full border-none bg-darker-fill hover:bg-fill"}`}
        >
          {discussionsExpanded ? <ArrowRight size={13} /> : <ArrowLeft size={13} />}
        </Button>

        <div className="grow">
          {discussionsExpanded && (
            <p className="text-xs font-semibold">{`${filteredDiscussions.length} ${t("Conversation")}${filteredDiscussions.length === 0 || filteredDiscussions.length > 1 ? "s" : ""}`}</p>
          )}
        </div>
        <DropdownMenu modal={false} onOpenChange={(open) => setFilterState((prev) => ({ ...prev, isSelected: open }))}>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="sm"
              className={cn(
                "size-7 p-1",
                "bg-background border-border hover:bg-fill",
                "focus-visible:ring-transparent",
                filterState.isFiltered ? "text-link hover:text-link" : "text--base-primary",
                filterState.isSelected && "bg-darker-fill hover:bg-darker-fill"
              )}
            >
              <ListFilter size={20} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-48">
            <DropdownMenuItem
              className={cn("flex items-center gap-2", filterState.activeFilter === "waiting" && "bg-fill")}
              onSelect={() => {
                applyFilter(filterWaitingDiscussions, "waiting", true)
              }}
            >
              <Bell size={16} />
              {t("OpenOnMe")}
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn("flex items-center gap-2", filterState.activeFilter === "open" && "bg-fill")}
              onSelect={() => {
                applyFilter(filterOpenDiscussions, "open", true)
              }}
            >
              <OpenIcon className="size-4" />
              {t("ShowOpen")}
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center gap-2"
              onSelect={() => {
                applyFilter(showAllDiscussions, "", false)
              }}
            >
              <MessageCircle size={16} />
              {t("ShowAll")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
