import { useEffect } from "react"
import { enrichDiscussionWithRelevance, sortDiscussionsBasedOnFileOrder } from "./utils"
import { useDiscussions } from "@/services/store/useDiscussions"
import { ChangeRequest } from "@/models/Changes"
import { GeneratedDiscussions } from "./GeneratedDiscussions"
import { Discussion, DiscussionState } from "@/models/Discussions"
import { DiscussionsHeader } from "@/components/designSystem/Discussions/DiscussionHeader"
import ResolvedIcon from "@/assets/icons/Resolved.svg?react"
import OutdatedIcon from "@/assets/icons/Outdated.svg?react"
import YellowOpenIcon from "@/assets/icons/YellowOpen.svg?react"
import { usePermissions } from "@/services/store/usePermissions"

export const iconsMap: Record<DiscussionState, JSX.Element> = {
  resolved: <ResolvedIcon />,
  open: <YellowOpenIcon />,
  pending: <YellowOpenIcon />,
  outdated: <OutdatedIcon />
} as const

interface Props {
  discussions: Discussion[]
  changeRequest: ChangeRequest
  filePaths: string[]
}

export const DiscussionsLayout = ({ discussions: discussionsFromBE, changeRequest, filePaths }: Props) => {
  const { setDiscussions, discussionsExpanded, setDiscussionsWithTop, filteredDiscussions } = useDiscussions()
  const commitSha = changeRequest?.commits[0]?.sha

  const { user } = usePermissions()

  useEffect(() => {
    const enrichedDiscussion = enrichDiscussionWithRelevance(discussionsFromBE, user)
    setDiscussions(sortDiscussionsBasedOnFileOrder(enrichedDiscussion, filePaths))
  }, [discussionsFromBE, filePaths, setDiscussions, user])

  useEffect(() => {
    setDiscussionsWithTop()
  }, [setDiscussionsWithTop, filteredDiscussions])

  return (
    <div
      id="discussion-wrapper"
      className={`flex flex-col ${discussionsExpanded ? "discussion-width-container" : "min-w-closed-discussion"}`}
    >
      <DiscussionsHeader />
      <div className="relative flex-1">
        <div className="relative w-full p-2">
          <GeneratedDiscussions discussions={filteredDiscussions} crId={changeRequest.id} commitSha={commitSha} />
        </div>
      </div>
    </div>
  )
}
