import { SpinnerLoader } from "@/components/designSystem/Loaders/SpinnerLoader"
import { useResolveComment } from "@/hooks/mutations/useResolveComment"
import { Discussion } from "@/models/Discussions"
import { CalendarClock } from "lucide-react"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Button, iconsMap, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { DiscussionStateChip } from "@/components/designSystem/Discussions/components/ExpandedDiscussion/DiscussionStateChip"
import { useChangeRequest } from "@/services/store/useChangeRequest"
import { useGithubRepoWriteAccess } from "@/hooks/useGithubRepoWriteAccess"
import { useDiscussions } from "@/services/store/useDiscussions"
import { TooltipToElement } from "@/components/designSystem/Tooltips/TooltipToElement"

interface Props {
  discussion: Discussion
  handleResolveToggle?: (resolvedState: boolean) => void
  isResolved?: boolean
}

export const ResolveController = ({ discussion, handleResolveToggle, isResolved }: Props) => {
  const { t } = useTranslation("Discussions")

  const { changeRequest } = useChangeRequest()

  const { toolTipContent, hasAccess } = useGithubRepoWriteAccess(changeRequest?.base_repo_full_name || "")

  const { resolveCommentMutation, status } = useResolveComment()
  const { toggleManualRecalculationTrigger } = useDiscussions()
  const isLoading = status === "pending"

  const handleResolve = useCallback(async () => {
    if (typeof handleResolveToggle !== "function" || isLoading) return

    if (!hasAccess) return

    await resolveCommentMutation({ id: discussion.id, state: isResolved ? "pending" : "resolved" })
    handleResolveToggle(!!isResolved)
    toggleManualRecalculationTrigger()
  }, [
    handleResolveToggle,
    isLoading,
    hasAccess,
    toggleManualRecalculationTrigger,
    resolveCommentMutation,
    discussion.id,
    isResolved
  ])

  // the height 34px is only to match the link button
  const BadgeComponent = (
    <TooltipToElement
      buttonAsAWrapper={true}
      elementTrigger={
        <Button
          onClick={handleResolve}
          variant={"outline"}
          className={`flex h-[34px] p-2 text-xxs ${hasAccess ? "cursor-pointer" : "cursor-not-allowed opacity-50"}`}
        >
          {isLoading ? <SpinnerLoader size="16px" /> : isResolved ? iconsMap["resolved"] : iconsMap["open"]}

          <span className="ml-1">{isResolved ? t("Resolved") : t("Open")}</span>
        </Button>
      }
      content={isResolved ? t("ClickToOpen") : t("ClickToResolve")}
    />
  )

  return (
    <div className="mr-1 flex items-center text-xxs">
      {discussion.discussion_state === "outdated" && (
        <DiscussionStateChip discussion={discussion} Icon={CalendarClock} tooltipContent={t("Outdated")} />
      )}
      {!hasAccess ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>{BadgeComponent}</TooltipTrigger>
            <TooltipContent>{toolTipContent}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        BadgeComponent
      )}
    </div>
  )
}
