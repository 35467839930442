import { useCallback } from "react"
import { X } from "lucide-react"
import { Discussion, NewCommentBubble as NewCommentBubbleI } from "@/models/Discussions"
import { useSyncElementPosition } from "../../useSyncElementPosition"
import { useDiscussions } from "@/services/store/useDiscussions"
import { Button, TooltipProvider } from "@/components"
import { NewCommentBubbleForm } from "./NewCommentBubbleForm"
import { calculateAdjustedTopPosition, DISCUSSION_STYLES } from "@/components/designSystem/Discussions/utils"

interface Props {
  current: NewCommentBubbleI
  prev?: Discussion | NewCommentBubbleI
  discussionNumber: number
  total: number
  discussions: Array<Discussion | NewCommentBubbleI>
  crId?: string
  commitSha?: string
}

export const NewCommentBubble = ({ current, discussionNumber, discussions, total, prev, crId, commitSha }: Props) => {
  const { topPosition } = useSyncElementPosition({ current, discussionNumber, discussions, total, prev })
  const {
    removeNewCommentBubbleFromDiscussions,
    clearCurrentOpenedDiscussion,
    expandedStateBeforeComment,
    minimizeDiscussionsContainer
  } = useDiscussions()

  const handleContainerMinimization = useCallback(() => {
    // check specifically if it is false
    if (expandedStateBeforeComment === false) {
      minimizeDiscussionsContainer()
    }
  }, [expandedStateBeforeComment, minimizeDiscussionsContainer])

  const handleClose = useCallback(() => {
    removeNewCommentBubbleFromDiscussions(current)
    clearCurrentOpenedDiscussion()
    handleContainerMinimization()
  }, [removeNewCommentBubbleFromDiscussions, current, clearCurrentOpenedDiscussion, handleContainerMinimization])

  return (
    <TooltipProvider>
      <div
        data-discussion-number={discussionNumber}
        style={{ top: calculateAdjustedTopPosition(topPosition) || 0 }}
        className={DISCUSSION_STYLES}
      >
        <div className="relative mb-2">
          <Button
            className="absolute right-1"
            size="sm"
            aria-label="Cancel comment"
            variant="ghost"
            onClick={handleClose}
          >
            <X width={16} height={16} />
          </Button>
        </div>

        <NewCommentBubbleForm
          current={current}
          crId={crId}
          commitSha={commitSha}
          handleContainerMinimization={handleContainerMinimization}
        />
      </div>
    </TooltipProvider>
  )
}
