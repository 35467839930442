import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components"
import { ReactNode } from "react"

interface IconTooltipProps {
  elementTrigger: ReactNode
  content: string
  buttonAsAWrapper?: boolean
  triggerClassName?: string
  contentClassName?: string
}

export const TooltipToElement = ({
  elementTrigger,
  content,
  buttonAsAWrapper = false,
  triggerClassName,
  contentClassName
}: IconTooltipProps) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger className={`cursor-default ${triggerClassName}`} asChild={buttonAsAWrapper}>
        {elementTrigger}
      </TooltipTrigger>
      <TooltipContent className={contentClassName}>{content}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
)
