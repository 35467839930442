import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { SkeletonLoader } from "@/components"
import { useFetchCodeDiff } from "@/hooks/queries/useFetchCodeDiff"
import { errorHandler } from "@/services/api/helpers"
import { useFetchChange } from "@/hooks/queries/useFetchChange"
import { CRHeader } from "./components/CRHeader/CRHeader"
import { CRFilters } from "./CRFilters"
import { DirectoryTree } from "./components/DirectoryTree"
import { PinLeftIcon } from "@radix-ui/react-icons"
import { useFetchTopics } from "@/hooks/queries/useFetchTopics"
import { HEADER_HEIGHT, useChangeRequest } from "@/services/store/useChangeRequest"
import { useScrollListener } from "@/hooks/useScrollListener"
import { useDiscussions } from "@/services/store/useDiscussions"
import { useFetchDiscussions } from "@/hooks/queries/useFetchDiscussions"
import { CRContentWrapper } from "./CRContentWrapper"
import { SuggestionDialog } from "@/components/designSystem/FCC/Table/components/SuggestionDialog"
import { useSuggestion } from "@/services/store/useSuggestion"
import { Topic } from "@/models/Topics"
import { useFetchBazzyOptions } from "@/hooks/queries/useFetchBazzyOptions"
import { useBazzy } from "@/services/store/useBazzy"
import { useDebouncedCallback } from "use-debounce"
import { useSelection } from "@/services/store/useSelection"
import { Scrollbar } from "@/pages/crFileChanges/components/Scrollbar"
import { usePermissions } from "@/services/store/usePermissions"
import { ScrollToAnchor } from "@/components/designSystem/ScrollToAnchor"
import { isDiscussion } from "@/components/designSystem/Discussions/utils"
import { useSidebarWidth } from "@/hooks/useSidebarWidth"
import { filterOldChangeRequests, PAGE_CONTAINER_PADDING } from "./utils"

const PREFIX = 9

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <div className={`${PAGE_CONTAINER_PADDING} flex w-full flex-col`}>{children}</div>
)

export const CRFileChanges = () => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { reachedTarget, resetScrollState } = useScrollListener(HEADER_HEIGHT)
  const [foldersTreeOpen, setFoldersTreeOpen] = React.useState(false)
  const [currentCRId, setCurrentCRId] = React.useState<string>("")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { clearSelection } = useSelection()

  const { changeRequest, setChangeRequest, setTopics, topics, resetHeaderHeight, incrementHeaderHeight } =
    useChangeRequest()
  const { setBazzyOptions, clearBazzyContent, toggleBazzy } = useBazzy()
  const { setDiffData } = useSuggestion()
  const {
    toggleManualRecalculationTrigger,
    expandDiscussionsContainer,
    filteredDiscussions,
    setCurrentOpenedDiscussion
  } = useDiscussions()
  const crContentSectionRef = useRef<HTMLElement | null>(null)

  // Derived values
  const number = useMemo(() => pathname.slice(pathname.lastIndexOf("/") + 1), [pathname])
  const codeBase = useMemo(() => pathname.slice(PREFIX, pathname.lastIndexOf("/")), [pathname])
  const tool = searchParams.get("tool")
  const topicFromParams = searchParams.get("topic")
  const commitSha = changeRequest?.commits[0]?.sha
  const { user } = usePermissions()

  // Queries
  const {
    data: changeRequestData,
    error: isHeaderError,
    isLoading: isHeaderLoading
  } = useFetchChange({ codeBase, number })

  const isAuthor = user?.github_login === changeRequestData?.author_name
  const { data: fetchedTopics } = useFetchTopics(currentCRId)

  document.title = `${changeRequestData ? `#${changeRequestData?.pr_number} ${changeRequestData?.title}` : "Baz"}`

  const topicId = useMemo(
    () => changeRequestData?.topics?.find((topic) => topic.name === topicFromParams)?.id ?? null,
    [changeRequestData, topicFromParams]
  )

  const {
    data: diffData,
    error: diffError,
    isLoading: diffLoading
  } = useFetchCodeDiff({
    changeId: currentCRId,
    tool,
    topicId
  })

  const {
    data: discussionsData,
    error: discussionsError,
    isLoading: discussionsLoading
  } = useFetchDiscussions({ changeId: currentCRId })

  const { data: bazzyOptions } = useFetchBazzyOptions()

  useSidebarWidth({
    conditional: {
      condition: foldersTreeOpen,
      whenTrue: 320,
      whenFalse: 0
    }
  })

  const handleResize = useDebouncedCallback(() => {
    setWindowWidth(window.innerWidth)
  }, 500)

  // Effects
  useEffect(() => {
    filterOldChangeRequests()
  }, [])

  useEffect(() => {
    if (fetchedTopics && (!topics || fetchedTopics.length !== topics.length)) {
      const newTopics = fetchedTopics.map((topic: Topic) => {
        const enrichedCommiters = topic.last_committers.map((commiter) => {
          return {
            ...commiter,
            githubUrl: `https://github.com/${codeBase}/commit/${commiter.commit_sha}`
          }
        })
        return {
          ...topic,
          last_committers: enrichedCommiters,
          isViewed: topic.files_details.every((file) => file.viewed.viewed === "viewed")
        }
      })
      setTopics(newTopics)
    }
  }, [fetchedTopics, setTopics, topics, codeBase])

  useEffect(() => {
    if (changeRequestData) {
      clearBazzyContent()
      toggleBazzy(false)
      clearSelection()
      setChangeRequest(changeRequestData)
      setCurrentCRId(changeRequestData.id)
      if (bazzyOptions) {
        setBazzyOptions(bazzyOptions)
      }
    }
  }, [
    bazzyOptions,
    changeRequestData,
    clearBazzyContent,
    clearSelection,
    setBazzyOptions,
    setChangeRequest,
    toggleBazzy
  ])

  useEffect(() => {
    if (diffData?.length) {
      setDiffData(diffData)
    }
  }, [diffData, setDiffData])

  useEffect(() => {
    const topicsHeight = document.querySelector("div[data-id=cr-filters]")?.getBoundingClientRect()?.height
    if (topicsHeight) {
      incrementHeaderHeight(topicsHeight)
    }

    return () => {
      resetHeaderHeight()
    }
  }, [topics, incrementHeaderHeight, resetHeaderHeight, windowWidth])

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize])

  // Reset minimized header state if scrollY is below breakpoint on topic/CR data changes
  useEffect(() => {
    if (window.scrollY <= HEADER_HEIGHT) resetScrollState()
  }, [topicFromParams, resetScrollState, changeRequestData])

  // Handlers
  const handleFoldersTree = useCallback(
    (isOpen: boolean) => {
      setFoldersTreeOpen(isOpen)
      toggleManualRecalculationTrigger()
    },
    [toggleManualRecalculationTrigger]
  )

  const scrollToAnchorSideEffect = useCallback(
    (hash: string) => {
      if (hash.startsWith("discussion")) {
        expandDiscussionsContainer()
        toggleManualRecalculationTrigger()

        const id = hash.slice(11)

        const discussionFromHash = filteredDiscussions.find((d) => d.id === id)
        if (discussionFromHash && isDiscussion(discussionFromHash)) {
          setCurrentOpenedDiscussion(discussionFromHash)
        }
      }
    },
    [expandDiscussionsContainer, toggleManualRecalculationTrigger, setCurrentOpenedDiscussion, filteredDiscussions]
  )

  const areFilesSorted = useMemo(() => diffData?.some((d) => d.order != null), [diffData])

  // Loading & Error states
  if (isHeaderLoading) {
    return (
      <Wrapper>
        <SkeletonLoader className="pt-6" />
      </Wrapper>
    )
  }

  if (isHeaderError || discussionsError || diffError) {
    return (
      <div className="flex w-full flex-col gap-4">
        <p>{errorHandler(isHeaderError || discussionsError || diffError)}</p>
      </div>
    )
  }

  return (
    <div className="flex w-full min-w-[1024px]">
      <aside
        className={`sticky top-16 flex h-0 bg-fill ${foldersTreeOpen ? "w-[280px] xl:min-w-default-discussion" : "hidden w-0"}`}
      >
        <div
          className={`
          no-scrollbar h-[calc(100vh-64px)] w-full overflow-y-auto overflow-x-hidden 
          border-r py-6 pl-12 pr-[22px] ${foldersTreeOpen ? "bg-fill" : "bg-background"}
        `}
        >
          <DirectoryTree onClose={() => handleFoldersTree(false)} files={diffData} Icon={PinLeftIcon} />
        </div>
      </aside>

      <section ref={crContentSectionRef} data-id="cr-content-section" className="mb-8 w-full">
        {changeRequestData && changeRequestData.commits[0] && (
          <>
            <CRHeader
              changeRequest={changeRequestData}
              isMinimalHeader={reachedTarget}
              isOpen={foldersTreeOpen}
              onOpen={() => handleFoldersTree(true)}
              foldersTreeOpen={foldersTreeOpen}
            />
            <CRFilters
              isFoldersTreeOpen={foldersTreeOpen}
              onOpen={() => handleFoldersTree(true)}
              areFilesSorted={areFilesSorted}
              hasReachedTarget={reachedTarget}
            />
            <CRContentWrapper
              changeRequestData={changeRequestData}
              isAuthor={isAuthor}
              diffData={diffData}
              diffError={diffError}
              diffLoading={diffLoading}
              discussionsError={discussionsError}
              discussionsLoading={discussionsLoading}
              discussionsData={discussionsData}
              hasReachedTarget={reachedTarget}
            />
            <ScrollToAnchor sideEffectCallback={scrollToAnchorSideEffect} />
          </>
        )}
        <SuggestionDialog crId={currentCRId} commitSha={commitSha} />
      </section>

      <Scrollbar ref={crContentSectionRef} />
    </div>
  )
}
