import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/Tooltip"
import { Discussion } from "@/models/Discussions"
import { LucideIcon } from "lucide-react"
import { useDiscussionHover } from "@/hooks/useDiscussionHover"

interface Props {
  onClick?: () => void
  discussion: Discussion
  Icon: LucideIcon
  tooltipContent?: string
}

export const DiscussionStateChip = ({ onClick, discussion, Icon, tooltipContent }: Props) => {
  const hoverProps = useDiscussionHover(discussion)

  const iconElement = onClick ? (
    <span
      role="button"
      tabIndex={0}
      aria-label="discussion state"
      {...hoverProps}
      onClick={onClick}
      className="flex size-6 cursor-pointer items-center justify-center rounded-2xl border-2 border-border"
    >
      <Icon width={14} height={14} className="text-primary" />
    </span>
  ) : (
    <Icon width={16} height={16} className="mr-2.5 cursor-default text-primary" />
  )

  if (!tooltipContent) return iconElement

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{iconElement}</TooltipTrigger>
        <TooltipContent>
          <span>{tooltipContent}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
