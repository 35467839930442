import { Discussion } from "@/models/Discussions"
import { useMemo, useState } from "react"
import { marginInPixels } from "../../useSyncElementPosition"
import { DiscussionBody } from "./DiscussionBody"
import { AddComment } from "@/components/designSystem/Discussions/components/Comment/AddComment"
import { calculateAdjustedTopPosition, DISCUSSION_STYLES } from "@/components/designSystem/Discussions/utils"
import { useDiscussionHover } from "@/hooks/useDiscussionHover"

interface Props {
  current: Discussion
  discussionNumber: number
  handleOpen: () => void
  isResolved: boolean
  handleResolveToggle: (resolveState: boolean) => void
  topPosition: number | null
  id: string
  crId: string
  commitSha: string
}

export const ExpandedDiscussion = ({
  current,
  discussionNumber,
  isResolved,
  handleResolveToggle,
  topPosition,
  id,
  crId,
  commitSha
}: Props) => {
  const hoverProps = useDiscussionHover(current)
  const [areAllCommentsExpanded, setAreAllCommentsExpanded] = useState(false)

  // marginInPixels is the top assigned to the discussion when it has no placement on the page
  const isDiscussionVisible = useMemo(() => topPosition && topPosition > marginInPixels, [topPosition])

  return (
    <div
      id={id}
      data-discussion-number={discussionNumber}
      style={{ top: calculateAdjustedTopPosition(topPosition) }}
      className={`${DISCUSSION_STYLES} ${isDiscussionVisible ? "absolute" : "hidden"}`}
      {...hoverProps}
    >
      <DiscussionBody
        comments={current?.comments}
        discussion={current}
        handleResolveToggle={handleResolveToggle}
        isResolved={isResolved}
        id={id}
        areAllCommentsExpanded={areAllCommentsExpanded}
        setAreAllCommentsExpanded={setAreAllCommentsExpanded}
      />

      {(!isResolved || areAllCommentsExpanded) && <AddComment discussion={current} crId={crId} commitSha={commitSha} />}
    </div>
  )
}
